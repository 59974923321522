import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";

export default function TimeoutDialog({ isOpen, onRequestClose }) {
    const navigate = useNavigate();

    const onLogOffCall = () => {
        navigate("/login");
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                fullWidth
                maxWidth="sm"
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"溫馨提示"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        自動登出程式啟動
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onLogOffCall}>登出</Button>
                    <Button onClick={onRequestClose} autoFocus>
                        保持登入
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
