import { useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import Container from '@mui/material/Container';
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider";
import { blue } from "@mui/material/colors";

const clientId = "xxx";

const Login = () => {
    const navigate = useNavigate();
    const onSuccess = (res) => {
        console.log("Login Success! Current user: ", res.profileObj);
        // e.preventDefault();
        navigate("/");
    }

    const onFailure = (res) => {
        console.log("Login Failed! res: ", res);
    }

    const login = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
    })

    return (

        <Container maxWidth="sm">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '25vh' }}
            />
            <Paper elevation={3}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '50vh' }}
                >
                    <Box sx={{ mt: 2 }}>
                        <Avatar
                            alt="customer-service-agent"
                            src="/img/icon/user.jpg"
                            sx={{ width: 100, height: 100 }}
                        />
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Typography variant="h5">AI Chat</Typography>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                                onSuccess(credentialResponse);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                        <Divider sx={{ py: 1.5 }}>or</Divider>
                        <Button sx={{ my: 1, width: 200, bgcolor: '#128c7e' }} variant="contained" onClick={() => navigate('/')}>以訪客身份繼續</Button>
                    </Box>
                </Grid>
            </Paper>
        </Container>
    )
}

export default Login;
