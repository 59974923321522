import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Avatar,
    Grid,
    Paper,
    IconButton,
    Snackbar,
} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import MuiAlert from "@mui/material/Alert";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import axios from "axios";
import { getEncoding, encodingForModel } from "js-tiktoken";
import { useNavigate } from "react-router-dom";

// components & utils
import TimeoutDialog from './components/TimeoutDialog';
import { addEventListeners, removeEventListeners } from './util/eventListenerUtil'


var messages = [
    { id: 1, text: "幫緊您，幫緊您", sender: "bot" },
];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const generateRandomMessageId = () => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var messageIdLength = 6;
    var messageId = "";

    for (var i = 0; i <= messageIdLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        messageId += chars.substring(randomNumber, randomNumber + 1);
    }
    return messageId;
}

const ChatUIWithGPT = ({model}) => {
    const [authenticated, setAuthenticated] = useState(null);
    const [input, setInput] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [warningMsg, setWarningMsg] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);
    const [msg, setMsg] = useState(messages);
    const [prevContext, setPrevContext] = useState([]);
    const divRef = useRef(null);
    const enc = getEncoding("gpt2");
    const navigate = useNavigate();

    // For timeout
    const [isTimeoutModalOpen, setTimeoutModalOpen] = useState(false);

    useEffect(() => {
        if (divRef.current) {
            divRef.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
        if (msg.length > 3) {
            setPrevContext([msg[msg.length - 1].text, msg[msg.length - 2].text]);
        }
    }, [msg]);

    // temp login auth flow, https://www.makeuseof.com/redirect-user-after-login-react/
    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        console.log('authenticated: ' + authenticated);
        if (loggedInUser) {
            setAuthenticated(loggedInUser);
            // Timeout Flow
            const createTimeout1 = () => setTimeout(() => {
                setTimeoutModalOpen(true);
            }, 60000);

            const createTimeout2 = () => setTimeout(() => {
                navigate("/login");
            }, 10000);

            const listener = () => {
                if (!isTimeoutModalOpen) {
                    clearTimeout(timeout);
                    timeout = createTimeout1();
                }
            }

            // Initialization
            let timeout = isTimeoutModalOpen ? createTimeout2() : createTimeout1();
            addEventListeners(listener);

            // Clean up
            return () => {
                removeEventListeners(listener);
                clearTimeout(timeout);
            }
        }
    }, [isTimeoutModalOpen]);

    // if (!authenticated) {
    //     return <Navigate replace to="/login" />;
    // }  

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenWarning(false);
    }

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    }

    const handleSendToGPT = () => {
        console.log('model: ' + model);
        
        let CHATGPT_API = 'https://chatgpt-api.sholdman.com/askv2';
        if (model === 'GPT-3.5') {
            CHATGPT_API = 'https://chatgpt-api.sholdman.com/askv2';
        } else if (model === 'DALL E-2') {
            CHATGPT_API = 'https://chatgpt-api.sholdman.com/create-image';
        }
        
        if (input.trim() != "") {
            setIsLoading(true);
            setInput("");
            console.log('input tokens: ' + enc.encode(input).length);

            if (!authenticated && enc.encode(input).length > 500) {
                setOpenWarning(true);
                setWarningMsg("登入可得到更好體驗");
                setIsLoading(false);
                return
            }

            if (enc.encode(input).length > 500) {
                setOpenWarning(true);
                setWarningMsg("哪有錢，你老母，條問題短啲啦!");
                console.error('No budget la, Input token too long');
                setIsLoading(false);
                return;
            }
            setMsg(msg => [...msg, { id: generateRandomMessageId(), text: input, sender: "user" }])
            var question = input.replaceAll('"', '\\"');

            // TODO: continus conversation
            /*
            if (prevContext.length > 0) {
                console.log('previous context: ' + JSON.stringify(prevContext));
                question = prevContext[1] + ' ' + prevContext[0] + ' ' + question + ' ';
                // TODO escape double quote
                question = question.replaceAll("'", "\\'").replaceAll('"', '\\"')
                console.log('question: ' + question);
            }*/

            var body = '{"prompt":"' + question + '"}';

            const customConfig = {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                timeout: 20000,
            }
            console.log(body);
            try {
                axios
                    .post(CHATGPT_API, body, customConfig)
                    .then(function (response) {
                        setMsg(msg => [...msg, { id: generateRandomMessageId(), text: response.data.message, sender: "bot", model: model }])
                        // keep previous message for continue conversation
                        setIsLoading(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        setOpenError(true);
                        if (error.code === 'ECONNABORTED') {
                            setErrorMsg("Timeout");
                        } else {
                            setErrorMsg("發生未知錯誤");
                        }
                        setIsLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }

        } else {
            console.error("Input is empty");
        }


    }

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    return (
        <Box
            sx={{
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                // bgcolor: "grey.200",
                backgroundImage: "url('img/chat-background.png')"

            }}
        >
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={openWarning} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
                    {warningMsg}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={openError} autoHideDuration={6000} onClose={handleErrorAlertClose}>
                <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            {isTimeoutModalOpen && (
                <TimeoutDialog
                    isOpen={isTimeoutModalOpen}
                    onRequestClose={() => setTimeoutModalOpen(false)}
                />
            )
            }
            <Box ref={divRef} sx={{ flexGrow: 1, overflow: "auto", p: 2, height: "calc(100vh - 136px)", }}>
                {msg.map((message) => (
                    <Message key={message.id} message={message} />
                ))}
                {isLoading ?
                    <Message message={{ id: generateRandomMessageId(), text: 'typing', sender: "bot" }} />
                    : <></>
                }
            </Box>
            <Box sx={{ 
                    p: 1.5, 
                    backgroundColor: "background.default", 
                    backgroundImage: "url('img/chat-background.png')",
                    position: "fixed",
                    bottom: 0,
                    width: "100%"
                }}>
                <Grid container>
                    <Grid item xs={12}>
                        {/* <TextField
                            InputProps={{
                                style: {
                                    backgroundColor: "white",
                                    borderRadius: "45px",
                                }
                            }}
                            size="medium"
                            fullWidth
                            placeholder="Please key in your question here. 請在此輸入問題"
                            variant="outlined"
                            value={input}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendToGPT()
                                }
                            }}
                        /> */}
                        <FormControl
                            sx={{ width: '100%' }}
                        >
                            <OutlinedInput
                                type="text"
                                sx={{ backgroundColor: 'white', borderRadius: '45px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                size="medium"
                                autoFocus="true"
                                fullWidth
                                placeholder="Please key in your question here. 請在此輸入問題"
                                variant="outlined"
                                value={input}
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSendToGPT()
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            onClick={handleSendToGPT}
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={1}>
                        <IconButton
                            size="large"
                            fullWidth
                            color="primary"
                            onClick={handleSendToGPT}
                        >
                            <SendIcon />
                        </IconButton>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
};

const Message = ({ message }) => {
    const isBot = message.sender === "bot";
    const botTyping = message.text === "typing";
    const model = message.model;

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: isBot ? "flex-start" : "flex-end",
                mb: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isBot ? "row" : "row-reverse",
                    // alignItems: "center",
                    alignItems: "flex-start"
                }}
            >
                {isBot ?
                    <Avatar alt="customer-service-agent" src="/img/icon/customer-service-agent.png" />
                    :
                    <Avatar alt="customer-service-agent" src="/img/icon/369.png" />
                }

                <Paper
                    variant="outlined"
                    sx={{
                        p: botTyping ? 1 : 2,
                        ml: isBot ? 1.5 : 0,
                        mr: isBot ? 0 : 1.5,
                        backgroundColor: isBot ? "rgb(245, 245, 245)" : "primary.light",
                        borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
                    }}
                    style={{
                        padding: 12
                    }}
                >
                    {botTyping ?
                        // https://lottiefiles.com/
                        <img src="img/icon/type-indicator-small.gif" alt="typing" height="25" />
                        :
                        <Box>
                            {model === "DALL E-2" ?
                            <Typography variant="body1" align="left" sx={{ whiteSpace: "pre-wrap" }}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: {xs: '256px', md: '512px'},
                                        width: {xs: '256px', md: '512px'}
                                    }}
                                    alt="dalle2"
                                    src={message.text}
                                />
                            </Typography>
                            :
                            <Typography variant="body1" align="left" sx={{ whiteSpace: "pre-wrap" }}><div dangerouslySetInnerHTML={{ __html: message.text }} /></Typography>
                            }
                        </Box>
                    }

                </Paper>
            </Box>
        </Box>
    );
};

export default ChatUIWithGPT;
